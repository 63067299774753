import React from "react";
import Image from "next/image";

const MobileLarge1 = ({ ...props }) => {

	const prop = props;

	return (
		<div className="mobile-large1">

			<a href={prop.link} className="article-image-container">
				<Image
					src={prop.image}
					alt="article"
					layout="fixed"
					objectFit="cover"
					// priority
					width={330}
					height={248}
					quality={70}
				/>
			</a>
			<a href={prop.catSlug} className="tag">
				{prop.category}
			</a>
			<a href={prop.link} className="title">{prop.title}</a>
			<p className="excerpt">
				{prop.excerpt}
			</p>
			<div className="author">
				<a href={prop.authorSlug} className="author-image-container">
					<Image
						src={prop.authorImage}
						alt="author"
						layout="fixed"
						objectFit="cover"
						width={17}
						height={17}
						quality={70}
					/>
				</a>
				<p className="author-name">
					<a href={prop.authorSlug}>
						{prop.authorName}
					</a>
					・
					{ prop.date }
				</p>
			</div>

			<style jsx>
				{`
          .mobile-large1 {
            display: flex;
            flex-flow: column;
          }
            .excerpt {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .author {
              display: flex;
              align-items: center;
              gap: 10px;
              margin: 16px 0 0 0;
            }
            .article-image-container {
              display: grid;
              width: 100%;
              overflow: hidden;
              aspect-ratio: 4/3;
            }
            .image-article {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .tag {
              font-family: Roboto;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              text-align: left;
              color: #E66983;
              text-transform: uppercase;
              margin: 16px 0 8px 0;
            }
            .title {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
              color: #000;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

            }
            .author-image-container {
              width: 17px;
              height: 17px;
              border-radius: 50%;
              overflow: hidden;
            }
            .author-name {
              font-family: Roboto;
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              text-align: left;
              color: #919EAB;
              text-transform: uppercase;
              margin: 0;
  
            }
            .author-name a {
              color: #919EAB;
            }
            
          `}
			</style>
		</div>
	);

};

export default MobileLarge1;
